footer {
  height: 142px;
  margin: 117px 0 0 0;
  flex-grow: 0;
  position: relative;

  p {
    margin: 0;
    padding: 56px 0 20px 0;
    box-sizing: border-box;
    font: 16px 'Museo-Light';
    text-align: center;
    color:#fff;
  }
}
.footer-links {
  display: flex;
  justify-content: center;
  padding: 0 0 25px 0;
  box-sizing: border-box;

  a {
    display: block;
    padding: 0 16px;
    box-sizing: border-box;
    font: 16px 'Museo-Light';
    text-align: center;
    text-decoration: none;
    color:#fff;
  }
}
.footer-img {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.footer-img--left {
  left: 0;
}
.footer-img--center {
  left: calc(50% - 266.5px);
}
.footer-img--right {
  right: 0;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {

  .footer-img--left {
    display: none;
  }

  .footer-img--right {
    display: none;
  }

}

@media screen and (max-width: 767px) {

  footer {
    height: auto;
    margin: 60px 0 0 0;
    padding: 15px 15px 0 15px;
    background-color: #abccd9;

    p {
      padding: 0;
      font: 14px 'Museo-Thin';
    }
  }
  .footer-links {
    flex-direction: column;
    padding: 0;
  
    a {
      margin: 15px 0;
      padding: 0;
      font: 12px 'Museo-Thin';
      &:last-child {
        margin: 0 0 15px 0;
      }
    }
  }
  .footer-img {
    display: none;
  }

}

