.main-button {
  display: block;
  padding: 16px 0;
  box-sizing: border-box;
  font: 14px 'Museo-Light';
  color: $white;
  background-color: $blue;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 25px;
  transition: 0.25s;
  &:hover {
    background-color: $blue-hover;
  }
}
.main-button--large {
  width: 200px;
}
.main-button--medium {
  width: 165px;
}
.main-button--small {
  width: 140px;
  padding: 10px 0;
  font: 12px 'Museo-Light';
}
.main-button--center {
  margin: 0 auto;
}


@media screen and (min-width: 768px) {

  .main-button--left {
    margin: 0 auto 0 0;
  }
  .main-button--right {
    margin: 0 0 0 auto;
  }

  .main-button--margin-top--25 {
    margin-top: 25px;
  }
  .main-button--margin-top--50 {
    margin-top: 50px;
  }
  .main-button--margin-top--100 {
    margin-top: 100px;
  }

}

@media screen and (max-width: 767px) {

  .main-button--center-m {
    margin: 0 auto;
  }

  .main-button--margin-top--20m {
    margin-top: 20px;
  }
  .main-button--margin-top--40m {
    margin-top: 40px;
  }

}