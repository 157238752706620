
@font-face {
  font-family: 'Museo-Thin';
  src: url("../font/MuseoSansCyrl-100.otf");
}

@font-face {
  font-family: 'Museo-Light';
  src: url("../font/MuseoSansCyrl-300.ttf");
}

@font-face {
  font-family: 'Museo-Medium';
  src: url("../font/MuseoSansCyrl-500.otf");
}

@font-face {
  font-family: 'Museo-Bold';
  src: url("../font/MuseoSansCyrl-700.otf");
}

@font-face {
  font-family: 'Museo-Black';
  src: url("../font/MuseoSansCyrl-900.otf");
}