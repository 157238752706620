header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  transition: 0.25s;
  &.active {
    background-color: #fff;
  }
}
.main-logo {
  display: block;
  width: 180px;
  height: 40px;
  background-image: url('../img/main-logo_d.svg');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 250;
  transition: 0.25s;
  &:hover {
    transform: scale(1.1);
  }
}
.header-links {
  display: flex;

  li {
    margin: 0 25px;
    &:first-child {
      margin: 0 25px 0 0;
    }
    &:last-child {
      margin: 0 0 0 25px;
    }
  }

  a {
    font: 17px 'Museo-Medium';
    color: $black;
    text-decoration: none;
    position: relative;
    &.active:after {
      width: 100%;
      transform: scale(1);
    }
    &:after {
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background-color: $blue;
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      transform: scale(0);
      transition: 0.25s;
    }
    &:hover:after {
      transform: scale(1);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {  

  header {
    padding: 20px;
  }
  .header-links {

    li {
      margin: 0 15px;
      &:first-child {
        margin: 0 15px 0 0;
      }
      &:last-child {
        margin: 0 0 0 15px;
      }

      a {
        font: 16px 'Museo-Medium';
      }
    }
  }

}

@media screen and (min-width: 768px) {

  .main-burger {
    display: none;
  }

  .header-links--hidden {
    display: none;
  }

}

@media screen and (max-width: 767px) {

  header {
    padding: 20px;
  }

  .main-logo {
    display: block;
    width: 6px;
    height: 21px;
    background-image: url('../img/main-logo_m.svg');
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.25s;
    &.main-logo-white {
      background-image: url('../img/main-logo_m-white.svg');
    }
  }

  .header-links {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $blue;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    li {
      width: 100%;
      margin: 20px 0 !important;
      text-align: center;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }

    a {
      font: 25px 'Museo-Light';
      color: #fff;
      &:after {
        background-color: $white;
      }
    }
  }
  .header-links.active {
    opacity: 1;
    z-index: 200;
  }

  .main-burger {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 250;
  }
  .main-burger--bar {
    display: block;
    width: 20px;
    height: 3px;
    border-radius: 10px;
    background-color: $blue;
    transition: all .3s ease-in-out;
    position: absolute;
  }
  .main-burger--bar-1 {
    top: 0;
  }
  .main-burger--bar-2 {
    top: calc(50% - 1.5px);
  }
  .main-burger--bar-3 {
    bottom: 0;
  }

  .main-burger.active {
    .main-burger--bar-1 {
      top: 9px;
      transform: rotate(45deg);
      background-color: #fff;
    }
    
    .main-burger--bar-2 {
      opacity: 0;
      background-color: #fff;
    }

    .main-burger--bar-3 {
      bottom: 8px;
      transform: rotate(-45deg);
      background-color: #fff;
    }
  }

  .header-links--hidden {
    display: block;
  }

}