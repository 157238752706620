$black: #000;
$white: #fff;
$blue: #00b7ff;
$blue-light: #119ed6;
$blue-hover: #0088bd;
$gray: #c4c4c4;
$gray-dark: #b5d8e6;
$gray-dark2: #999;

html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.main-wrapper {
  flex-grow: 1;
  position: relative;
  z-index: 5;
}

.container {
  width: 100%;
  max-width: 1246px;
  margin: 0 auto;
}

.main-header {
  width: 100%;
  max-width: 718px;
  margin: 0 auto;
  padding: 378px 0 50px 0;
  box-sizing: border-box;
  font: 40px 'Museo-Black';
}
.main-header--center {
  text-align: center;
}

.main-header--section {
  display: flex;
  align-items: center;
  padding: 50px 0 100px 0;
  box-sizing: border-box;
}
.main-header--section--name {
  margin: 0 0 0 -50px;
  font: 40px 'Museo-Black';
  letter-spacing: 4px;
  text-transform: uppercase;
  color: $black;
}
.main-header--section--circle {
  display: block;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  background-color: $gray-dark;
  position: relative;
  z-index: -1;
}
.main-header--section--line {
  display: block;
  height: 1px;
  width: 100vw;
  background-color: $gray-dark;
  position: absolute;
  top: calc(50% - 0.5px);
  left: -100vw;
}

.main-content {

  p {
    margin: 20px 0 0 0;
    font: 14px 'Museo-Light';
    line-height: 1.4;
    &:first-child {
      margin: 0;
    }
  }
}

.main-content--text {
  font: 14px 'Museo-Light';
  line-height: 1.4;
}

.parallax {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  img {
    width: 100%; 
  }
}
.parallax-img--parallax {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header-section {
  font: 25px 'Museo-Medium';
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.header--center {
  text-align: center;
}

.news {
  display: flex;
  margin: 0 0 50px 0;
  &:last-child {
    margin: 0;
  }
}
.news--top {
  .news-img--wrapper {
    &:before {
      display: block;
      content: '';
      width: 140px;
      height: 140px;
      border: solid 5px $blue;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .news-img {
    .news-img--i {
      display: block;
    }
  }

  .news-content {
    i {
      background-color: $blue;
    }
  }
}
.news-img {
  width: 150px;
  height: 150px;
  margin: 0 43px 0 0;
  position: relative;
}
.news-img--wrapper {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.news-img--main {
  display: block;
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.news-img--i {
  display: none;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
}
.news-content {
  width: calc(100% - 193px);

  span {
    display: block;
    padding: 0 0 9px 0;
    font: 12px 'Museo-Thin';
    color: $gray;
  }

  i {
    display: block;
    width: calc(100% + 43px);
    height: 1px;
    position: relative;
    left: -43px;
    background-color: $gray;
  }

  p {
    margin: 0;
    font: 14px 'Museo-Light';
    line-height: 1.41;
    color: $black;
    a {
      color: $blue-light;
    }
  }
}

.news-content--text {
  max-height: 56px;
  margin: 15px 0;
  font: 14px 'Museo-Light';
  line-height: 1.41;  
  color: $black;
  transition: 0.25s;
  overflow: hidden;
  a {
    color: $blue-light;
  }
}

.products-wrapper {
  margin: 145px 0 0 0;
  position: relative;
}
.product {
  display: flex;
  justify-content: space-between;
  margin: 45px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
.product-img--wrapper {
  flex-basis: 50%;
}
.product-img {
  width: max-content;
  position: relative;
}
.product-img--preview {
  border-radius: 50%;
  position: relative;
}
.product-img--logo {
  border-radius: 50%;
  position: absolute;
  top: 0;
  z-index: 5;
}
.product-content--wrapper {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}
.product-content {
  width: 100%;
  max-width: 610px;
  padding: 10px 50px;
  box-sizing: border-box;
  
  h2 {
    margin: 0 0 34px 0;
    font: 30px 'Museo-Black';
  }

  p {
    margin: 0;
    font: 14px 'Museo-Medium';
  }
}

.img-graphic {
  display: block;
  position: absolute;
  z-index: -1;
}
.img-graphic--right {
  right: 0;
  top: 0;
  transform: translateY(-50%);
}
.img-graphic--left {
  left: 0;
  bottom: 35%;
}

.products-grid {
  display: flex;
  justify-content: space-between;
  margin: 75px 0 0 0;
}
.product-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
}
.product-grid-img--wrapper {
  position: relative;
}
.product-grid-img--preview {
  display: block;
  width: 296px;
  margin: 0 auto;
  border-radius: 50%;
}
.product-grid-img--logo {
  display: block;
  width: 150px;
  border-radius: 50%;
  position: absolute;
  top: -75px;
  left: calc(50% - 75px);
}
.product-grid--content {

  h3 {
    margin: 50px 0 40px 0;
    font: 18px 'Museo-Black'; 
    text-align: center;
  }

  p {
    margin: 0;
    font: 14px 'Museo-Medium';
    text-align: center;
  }
}

.product-banner {
  display: block;

  img {
    display: block;
    width: 100%;
  }
}

.product-item--wrapper {
  padding: 50px 0;
  box-sizing: border-box;
  border-top: 1px solid $gray;
}
.product-item {
  display: flex;
}
.product-item--preview {
  width: 175px;
  margin: 0 140px 0 0;
}
.product-item--preview--img {
  display: block;
  width: 100%;
  border-radius: 50%;
}
.product-item--desc {
  width: calc(100% - 315px);
}
.product-item--content {
  
  h3 {
    width: 100%;
    max-width: 630px;
    margin: 0 0 25px 0;
    font: 25px 'Museo-Bold';
    line-height: 1.4;
  }

  p {
    margin: 10px 0;
    font: 13px 'Museo-Light';
    line-height: 1.4;
  }

  li {
    margin: 5px 0;
    padding: 0 0 0 15px;
    font: 13px 'Museo-Light';
    line-height: 1.4;
    position: relative;
    &:before {
      display: block;
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $black;
      position: absolute;
      top: calc(50% - 2px);
      left: 0;
    }
  }
}
.product-item--info {
  margin: 20px 0 0 0;

  h3 {
    margin: 0 0 10px 0;
    color: $gray-dark2;
    font: 16px 'Museo-Medium';
  }

  p {
    margin: 10px 0 0 0;
    font: 13px 'Museo-Light';
    line-height: 1.4;
  }
}

.about-products--grid {
  display: flex;
  justify-content: space-around;
  margin: 50px 0 0 0;
}
.about-product--grid {
  width: 150px;
}
.about-product--grid--img {
  display: block;
  width: 100%;
}
.about-product--grid--link {
  display: block;
  margin: 50px 0 0 0;
  font: 14px 'Museo-Medium';
  text-align: center;
  text-decoration: none;
  color: $blue;
}

.contacts-wrapper {
  display: flex;
  justify-content: space-between;
}
.adress {
  flex-basis: 45%;
  
  h2 {
    margin: 0;
    padding: 0 0 15px 0;
    box-sizing: border-box;
    font: 25px 'Museo-Bold';
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: $black;
    position: relative;
    &:after {
      display: block;
      content: '';
      width: 158px;
      height: 1px;
      background-color: $gray;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  p {
    margin: 25px 0 0 0;
    font: 14px 'Museo-Light';
    line-height: 1.4;

    a {
      color: $blue-light;
      text-decoration: none;
    }
  }
}
.adress-map {
  width: 100%;
  height: 470px;
  margin: 100px 0 0 0;
  border-radius: 10px;
}
.adress-map--margin-top-150 {
  margin: 150px 0 0 0;
}

.line-vert {
  display: block;
  width: 1px;
  height: 75px;
  margin: 50px auto 50px auto;
}
.line-vert--blue {
  background-color: $blue;
}

.link--download {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: max-content;

  img {
    display: block;
    margin: 0 15px 0 0;
  }

  p {
    margin: 0;
    font: 14px 'Museo-Bold';
    color: $black;
    position: relative;
    &:after {
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background-color: $blue;
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      transform: scale(0);
      transition: 0.25s;
    }
    &:hover:after {
      transform: scale(1);
    }
  }
}
.link--download--margin-top-50 {
  margin-top: 50px;
}
.link--download--margin-bottom-50 {
  margin-bottom: 50px;
}
.link--download--margin-top-25 {
  margin-top: 25px;
}
.link--download--margin-bottom-25 {
  margin-bottom: 25px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (min-width: 1601px) {

  .product-img {
    max-width: 750px;
  }

  .product-img--preview {
    width: 750px;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

  .product-img {
    max-width: 500px;
  }

  .product-img--logo {
    width: 200px;
  }
  .product-img--preview {
    width: 500px;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

  .container {
    max-width: 1000px;
  }

  .product-grid-img--preview {
    width: 220px;
  }
  .product-grid-img--logo {
    width: 120px;
    top: -60px;
    left: calc(50% - 60px);
  }

}

@media screen and (max-width: 1199px) {

  .img-graphic {
    display: none;
  }

}

@media screen and (min-width: 1024px) and (max-width: 1199px) {

  .container {
    max-width: 900px;
  }

  .product-img {
    max-width: 450px;
  }

  .product-img--preview {
    width: 450px;
  }
  .product-img--logo {
    width: 180px;
  }
  .product-content {
    padding: 10px 40px;

    h2 {
      font: 25px 'Museo-Black';
    }
  }

}

@media screen and (min-width: 768px) and (max-width: 1199px) {

  .products-grid {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .product-grid {
    margin: 0 0 100px 0;
    &:last-child {
      margin: 0;
    }
  }

  .product-item--preview {
    margin: 0 100px 0 0;
  }

  .contacts-wrapper {
    flex-direction: column;
  }
  .adress {
    flex-basis: 100%;
    &:first-child {
      margin-bottom: 100px;
    }
  }
  .adress-map {
    margin: 40px 0 0 0 !important;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .container {
    max-width: 700px;
  }

  .padding-top-80t {
    padding-top: 80px;
  }

  .product {
    margin: 100px 0 0;
  }
  
  .product-img {
    max-width: 300px;
  }

  .product-img--preview {
    width: 300px;
  }
  .product-img--logo {
    width: 120px;
  }
  .product-content {
    padding: 10px 40px;

    h2 {
      font: 25px 'Museo-Black';
    }
  }

}

@media screen and (min-width: 768px) {

  .hide-desktop {
    display: none;
  }

  .padding-top--90 {
    padding-top: 90px;
  }

  .main-logo-mobile {
    display: none;
  }

  .main-banner-mobile {
    display: none;
  }

  .product-img--left {
    margin: 0 auto 0 0;
  }
  .product-img--right {
    margin: 0 0 0 auto;
  }
  .product-img--preview--left {
    left: -48px;
  }
  .product-img--preview--right {
    right: -48px;
  }
  .product-img--logo--left {
    right: 0;
  }
  .product-img--logo--right {
    left: 0;
  }
  .product-content--wrapper--left {
    justify-content: flex-start;
  }
  .product-content--wrapper--right {
    justify-content: flex-end;
  }
  .product-content--left {
    border-left: 1px solid $blue;
  
    h2 {
      text-align: left;
    }
  
    p {
      text-align: left;
    }
  }
  .product-content--right {
    border-right: 1px solid $blue;
  
    h2 {
      text-align: right;
    }
  
    p {
      text-align: right;
    }
  }

  .margin-top--25 {
    margin-top: 25px;
  }
  .margin-bottom--25 {
    margin-bottom: 25px;
  }

}

@media screen and (max-width: 767px) {

  .container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .main-content {

    p {
      font: 13px 'Museo-Light';
      line-height: 1.4;
    }
  }

  .main-banner-mobile {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;

    img {
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  .padding-top--60m {
    padding-top: 60px;
  }

  .parallax {
    display: none;
  }

  .main-logo-mobile {
    display: block;
    margin: 110px auto 60px auto;
  }

  .main-header {
    padding: 0 0 40px 0;
    font: 24px 'Museo-Black';
  }

  .main-header--section {
    padding: 20px 0 40px 0;
  }
  .main-header--section--circle {
    width: 49px;
    height: 49px;
  }
  .main-header--section--name {
    margin: 0 0 0 -25px;
    font: 25px 'Museo-Black';
  }

  .header-section {
    margin: 40px 0;
  }

  .line-vert {
    display: block;
    width: 1px;
    height: 40px;
    margin: 40px auto ;
  }
  .line-vert--blue {
    background-color: $blue;
  }

  .news-img {
    display: none;
  }
  .news-content {
    width: 100%;

    i {
      display: none;
    }
  }
  .news {
    margin: 0 0 40px 0;
  }

  .products-wrapper {
    margin: 110px 0 0 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .product {
    flex-direction: column;
    margin: 110px 0 0 0;
  }
  .product--right {
    flex-direction: column-reverse;
  }
  .product-img--wrapper {
    flex-basis: 100%;
  }
  .product-img {
    width: 100%;
  }
  .product-img--preview {
    display: block;
    width: 200px;
    margin: 0 auto;
  }
  .product-img--logo {
    width: 100px;
    top: -50px;
    left: calc(50% - 50px);
  }
  .product-content {
    padding: 0;
    text-align: center;

    h2 {
      margin: 40px 0;
      font: 18px 'Museo-Bold';
    }
  }

  .about-products--grid {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 40px 0 0 0;
  }
  .about-product--grid {
    margin: 0 0 36px 0;
    &:last-child {
      margin: 0;
    }
  }
  .about-product--grid--link {
    margin: 0;
  }

  .products-grid {
    flex-direction: column;
    margin: 50px 0 0 0;
  }
  .product-grid {
    width: 100%;
    margin: 110px 0 0 0;
    &:first-child {
      margin: 0;
    }
  }
  .product-grid-img--preview {
    width: 200px;
  }
  .product-grid-img--logo {
    width: 100px;
    top: -50px;
    left: calc(50% - 50px);
  }
  .product-grid--content {

    h3 {
      margin: 40px 0 40px;
    }
  }

  .product-item--wrapper {
    padding: 40px 0;
  }
  .product-item {
    flex-direction: column;
  }
  .product-item--preview {
    width: 100%;
    margin: 0 0 25px 0;
  }
  .product-item--preview--img {
    max-width: 175px;
    margin: 0 auto;
  }
  .product-item--desc {
    width: 100%;
  }
  .product-item--content {
    h3 {
      font: 18px 'Museo-Bold';
      line-height: 1.4;
    }
  }

  .contacts-wrapper {
    flex-direction: column;
  }
  .adress {
    flex-basis: 100%;
    &:first-child {
      margin-bottom: 40px;
    }

    h2 {
      &:after {
        width: 90px;
      }
    }
  }
  .adress-map {
    height: 280px;
    margin: 40px 0 0 0;
  }

  .hide-mobile {
    display: none;
  }

}